import React, { useEffect, useState } from 'react'
import "./Admin.css"
import { useDispatch, useSelector } from 'react-redux'
import { getAdminsList } from '../Redux/Actions/Admin/Admin';
import { Button, Table } from 'antd';
import ExcelJS from 'exceljs';
import { Modal } from 'antd';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admins = useSelector((state) => state?.Admin?.getAdmins)
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log("admins",admins);
  
 
  useEffect(() => {
    dispatch(getAdminsList());
  },[])

  const onRefreshClick = () => {
    dispatch(getAdminsList());
    setSelectedRowKeys([])
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Joined Date',
      dataIndex: 'date',
    },
  ];

  const dataSource = [];
  {
    admins?.map((item, index) => {
      return dataSource?.push({
        key: index,
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.adminEmail,
        phoneNumber: item?.adminPhoneNumber,
        status: item?.status,
        date: dayjs(item.createdAt).format('DD/MM/YYYY')
      })
    })
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };
  
  const exportToExcel = async () => {
    const selectedItems = dataSource.filter((item, index) => selectedRowKeys.includes(index));    

    if (selectedItems.length === 0) {
      alert("No items selected for export.");
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Selected Data');

    worksheet.columns = [
      { header: 'First Name', key: 'firstName', width: 15 },
      { header: 'Last Name', key: 'lastName', width: 15 },
      { header: 'Admin Email', key: 'adminEmail', width: 15 },
      { header: 'Admin Phone Number', key: 'adminPhoneNumber', width: 15 },
      { header: 'Status', key: 'status', width: 15 },
      { header: 'Joined Date', key: 'date', width: 15 },
    ];

    selectedItems.forEach(item => {
      worksheet.addRow({
        firstName: item.firstName,
        lastName: item.lastName,
        adminEmail: item.email,
        adminPhoneNumber: item.phoneNumber,
        status: item.status,
        date: item.date,
      });
    });

    const arrayBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([arrayBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    const link = document.createElement('a');
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = 'Admin_Waitlist.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    Cookies.remove("access_token");
    navigate("/login")
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  
  return (
    <div className='admin-container'>
      <div className='logout-container'>
        <Button onClick={showModal}>Logout</Button>
      </div>
      <div className='total-refresh-container'>
        <p>Total Users: {admins?.length}</p>
        <div>
          <Button onClick={exportToExcel}>Export</Button>
          <Button className='refresh-button' onClick={onRefreshClick}>Refresh</Button>
        </div>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={dataSource} />
      <Modal title="Confirm Logout" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        You'll be logged out from Admin's Dashboard!
      </Modal>
    </div>
  )
}

export default Admin